<template>
    <div class="cristmas-page">
        <div class="cristmas-page__content">
            <div class="title__wrapper">
                <h1 class="mb-10 mr-24">Kūčių rinkinys</h1>
            </div>

            <div>
                <Slider
                :slides-per-view="1"
                :space-between="1"
                >
                    <SwiperSlide v-for="image in 5" :key="image">
                        <img class="cristmas-page__image" :src="require(`@/assets/images/cristmas/City_Rush_Xmas-${image}.jpg`)" alt="">
                    </SwiperSlide>
                </Slider>
            </div>
            <br>
            <h3>Aprašymas</h3>
            <br>
            <p>Rinkinys puikiai tinka dviems asmenims, tačiau ir tryse juo galėsite pasimėgauti</p>
            <p>Kaina 2-3 asmenims 149,- Eur</p>
            <p>Užsakant du rinkinius ir daugiau, antrojo rinkinio kaina tik 99,- Eur</p>
            <br>
            <p>Pristatymai vyks Vilniuje, Kaune ir Klaipėdoje</p>
            <p>Išsiuntus užklausą mes su jumis susisieksime ir aptarsime visas detales</p>
            <p>Užsakymai Kūčių stalui priimami iki 12.21 imtinai</p>
            <p>Kūčių stalo rinkinio užsakymui nuolaidos kodai nėra taikomi </p>


            <br>
            <h3>Kūčių stalo meniu:</h3>
            <br>
            <ol class="cristmas-page__list">
                <li>Miško spanguolių kisielius</li>
                <li>Tradicinis aguonų pienas</li>
                <li>Kūčiukai iš speltos miltų, su medumi ir aguonomis</li>
                <li>Šviežiai kepta rūginių miltų juoda duona su džiovintų grybų užtepėle</li>
                <li>Matjes silkė keptų svogūnų ir morkų patale</li>
                <li>Silkės tartaras su obuolių ir graikinių riešutų akcentu </li>
                <li>Naminis marinuotų grybų asorti</li>
                <li>Rūkytos skumbrės triufeliai apibarstyti juodos duonos trupiniais</li>
                <li>Tarkuotomis daržovėmis užkepti jūros lydekos kepsneliai.</li>
                <li>Lašišos Wellington</li>
                <li>Ilgai brandintas kūčių pyragas su džiovintais vaisiais ir cukatais</li>
                <li>Avižų keksiukai su riešutais ir aviečių glajumi</li>
            </ol>
            <br>
            <h3>Kūčių stalo užklausos forma:</h3>


            <h4>Jūsų vardas ir pavardė</h4>
            <CRInput
                class="input"
                :name="'name'"
                v-model="form.name"
                :placeholder="$t('placeholder.enterYourName')"
                required
                :errors="errors?.name"
                @onChange="$filters.clearError(errors, 'name')"
            />

            <h4>Jūsų telefono numerio</h4>
            <CRInput
                class="input"
                :name="'phone'"
                v-model="form.phone"
                :placeholder="'Jūsų telefono numerio'"
                required
                :errors="errors?.phone"
                @onChange="$filters.clearError(errors, 'phone')"
            />

            <h4>Jūsų elektroninio pašto adresas *</h4>
            <CRInput
                class="input"
                :name="'email'"
                v-model="form.email"
                :placeholder="'El. paštas'"
                required
                :errors="errors?.email"
                @onChange="$filters.clearError(errors, 'email')"
            />

            <h4>Jūsų adresas</h4>
            <CRInput
                class="input"
                :name="'address'"
                v-model="form.address"
                :placeholder="'Jūsų adresas'"
                required
                :errors="errors?.address"
                @onChange="$filters.clearError(errors, 'address')"
            />

            <h4>Durų/vartelių kodas</h4>
            <CRInput
                class="input"
                v-model="form.doorCode"
                :placeholder="'Durų/vartelių kodas'"
                required
                :errors="errors?.doorCode"
                @onChange="$filters.clearError(errors, 'doorCode')"
            />

            <h4>Kūčių stalo rinkinių skaičius</h4>
            <CRRadio
                class="radio"
                v-for="setCount in setCounts"
                v-model="form.setCount"
                :key="setCount.id"
                :value="setCount.id"
            >
                {{ setCount.name }}
            </CRRadio>

            <div v-show="deliveryOptions.length">
                <h4>Pristatymo laikas *</h4>
                <CRSelect
                    class="city-select"
                    :data="deliveryOptions"
                    :selected="form?.delivery"
                    @select="setSelectedDelivery"
                >
                    <template #icon>
                        <LocationSvg class="icon-location" />
                    </template>
                </CRSelect>
            </div>

            <h4>Atsiskaitymo būdas *</h4>
            <CRRadio
                class="radio"
                v-for="payment in paymentMethods"
                v-model="form.payment"
                :key="payment.id"
                :value="payment.id"
            >
                {{ payment.name }}
            </CRRadio>

            
            <div v-show="form.payment === 2">
                <h4>Kita</h4>
                <CRInput
                    class="input"
                    v-model="form.other"
                    :placeholder="'Kita'"
                    required
                    :errors="errors?.other"
                    @onChange="$filters.clearError(errors, 'other')"
                />
            </div>

            <h4>Komentarai administracijai, virtuvei, kurjeriui</h4>
            <CRTextarea
                class="input"
                v-model="form.message"
                :placeholder="'Komentarai administracijai, virtuvei, kurjeriui'"
                required
                :errors="errors?.message"
                @onChange="$filters.clearError(errors, 'message')"
            />
            <br>
            <CRButton @click="submitForm">Siųsti</CRButton>
            <br>
        </div>
    </div>
</template>
<script>
import CRRadio from '@/components/Common/Radio/CRRadio.vue';
import CRInput from '@/components/Common/Inputs/CRInput.vue';
import CRButton from '@/components/Common/Buttons/CRButton.vue';
import CRSelect from '@/components/Common/Select/CRSelect.vue';
import CRTextarea from '@/components/Common/Textarea/CRTextarea.vue';
import LocationSvg from '@/assets/icons/navbar/location.svg?inline';
import { mapGetters } from 'vuex';
import axios from 'axios';
import { TYPE } from 'vue-toastification';
import Slider from '@/components/Common/Slider/Slider.vue';
import { SwiperSlide } from 'swiper/vue/swiper-slide';

export default {
    components: {
        CRInput,
        CRButton,
        CRTextarea,
        CRRadio,
        CRSelect,
        LocationSvg,
        Slider,
        SwiperSlide,
    },
    data() {
        return {
            images: [
                {
                    name: 'City_Rush_Xmas-1.jpg',
                },
            ],
            errors: {},
            isLoading: false,
            deliveryOptions: [
                {
                    label: 'Vilnius 08:00-12:00 (24d)',
                    value: 0,
                },
                {
                    label: 'Vilnius 18:00-23:00 (23d)',
                    value: 1,
                },
                {
                    label: 'Kaunas 08:00-12:00 (24d)',
                    value: 2,
                },
                {
                    label: 'Kaunas 20:00-23:00 (23d)',
                    value: 3,
                },
                {
                    label: 'Klaipėda 21:00-23:00 (23d)',
                    value: 4,
                },
            ],
            paymentMethods: [
                {
                    id: 0,
                    name: 'Grynais (paduodant kurjeriui pristatymo metu)',
                },
                {
                    id: 1,
                    name: 'Pavedimu',
                },
                {
                    id: 2,
                    name: 'Kita',
                },
            ],
            setCounts: [
                {
                    id: 0,
                    name: '2 žmonėms (149 Eur)',
                },
                {
                    id: 1,
                    name: '3-4 Žmonėms (Užsakant du rinkinius, antro rinkinio kaina tik 99Eur)',
                }
            ],
            form: {
                doorCode: '',
                address: '',
                delivery: '',
                name: '',
                email: '',
                phone: '',
                message: '',
                setCount: 0,
                payment: 0,
                other: '',
            },
        }
    },
    computed: {
        ...mapGetters('city', ['selectedCity', 'citiesForSelect']),
    },
    methods: {
        setSelectedDelivery(val) {
            this.form.delivery = val.value;
        },
        async submitForm() {           
            await axios.post(api.env.API_ENDPOINT + '/api/christmas-order', this.form).then((response) => {
                
                this.$filters.toast(response.data.message, { type: TYPE.SUCCESS });

                this.form = {
                    doorCode: '',
                    address: '',
                    delivery: 0,
                    city: 0,
                    name: '',
                    email: '',
                    message: '',
                    setCount: 0,
                    payment: 0,
                    other: '',
                };
            }).catch((error) => {
                console.log(error);
                this.errors = error.response.data.errors;
            });
        }
    }
}
</script>
<style lang="scss" scoped>
     :deep(.slider .swiper-pagination-bullet) {
        text-align: center;
        line-height: 40px;
        font-size: 24px;
        opacity: 1;
        background: $grey-form;
    }
    :deep(.slider .swiper-pagination-bullet-active) {
        background: $primary;
    }

    .cristmas-page {

        &__list {
            list-style-type: decimal;
            padding-left: 15px;
        }

        &__image {
            border-radius: 10px;
        }

        .cr-select  {
            width: 100%;
        }

        .cr-radio {
            margin-bottom: 5px;
        }

        .title__wrapper {
            @include row-align-center;
            flex-wrap: wrap;

            margin-bottom: 10px;
        }
        &__content {
            max-width: 500px;
            margin: 0 auto;

            h4 {
                margin-top: 20px;
                padding-bottom: 10px;
            }
        }
    }
</style>